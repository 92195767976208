import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ShareButtons from "../components/share-buttons"
import Breadcrumb from '../components/breadcrumbs'
import { GreenButtonRedirect } from "../components/button-green-redirect"

class BlogPageTemplate extends React.Component {
    render() {
        const blogpage = this.props.data.contentfulBlog
        const image = getImage(blogpage.image)
        const url = "https://1stcalljunkhaulsac.com/" + blogpage.slug
        return (
            <Layout>
                <Seo
                    title={blogpage.title}
                    description={blogpage.description}
                    location={blogpage.slug}
                    blogpage={blogpage}
                    isBlogPost={true}
                    type="BlogPosting"
                    img={getSrc(image)}
                    keywords={[blogpage.title]}
                />
                <div>
                    <div className="border border-gray-200 shadow-lg pt-1">
                        <nav className="flex container mx-auto px-2 sm:px-6 py-3" aria-label="Breadcrumb">
                            <Breadcrumb links={[
                                { to: '/', label: 'Home' },
                                { to: '/blog', label: 'Blog' },
                                { to: blogpage.slug, label: blogpage.title },
                            ]} />
                        </nav>
                    </div>
                    <div className="container mx-auto px-5 lg:px-10 pt-5 lg:pt-16 pb-4 lg:pb-10 max-w-4xl mx-auto">
                        <div className="max-w-4xl xl:mx-auto 2xl:ml-blogHeading pb-4">
                            <h1 className="py-2 font-bold text-2xl md:text-3xl lg:text-4xl">{blogpage.title}</h1>
                            <p className="font-light">By {blogpage.author} - {blogpage.datePublished}</p>
                        </div>
                        <div className="flex justify-center">
                            <GatsbyImage className="w-full xl:w-9/12 2xl:w-blog shadow-lg rounded-lg z-0" src="" image={image} title={blogpage.image.title} alt={blogpage.image.description} />
                        </div>
                        <div className="max-w-5xl xl:max-w-4xl mx-auto bg-white mt-4 px-5 rounded-lg shadow-lg">
                            <div className="flex mx-auto">
                                <span className="py-2 px-2 text-sm ">Share This Article:</span>
                                <div className="pt-2">
                                    <ShareButtons url={url} description={blogpage.description} title={blogpage.title} alt={blogpage.title} />
                                </div>
                            </div>
                        </div>
                        <div className="pt-10 max-w-4xl mx-auto leading-8" dangerouslySetInnerHTML={{
                            __html: this.props.data.contentfulBlog.body.childMarkdownRemark.html,
                        }}>
                        </div>

                        <p className="text-center text-2xl max-w-xl font-bold mx-auto pt-10">Schedule your 1st Call Junk Haul Sacramento Service Today!</p>
                        <div className="text-center flex justify-center mx-auto py-5">
                            <GreenButtonRedirect buttonText="Book Now" />
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
export default BlogPageTemplate
export const pageQuery = graphql`
query ContentfulBlogBySlug($slug: String !) {
    site {
        siteMetadata {
            title
        }
    }
    contentfulBlog( slug: {eq: $slug }) {
        title
        slug
        author
        body {
            childMarkdownRemark {
                html
            }
         }
        description
        datePublished(formatString: "MMMM Do, YYYY")
        dateModified(formatString: "MMMM Do, YYYY")
        image {
             gatsbyImageData (
                width: 600
                height: 450
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
                quality: 100
                )
                title
                description
             }
    }
}
`